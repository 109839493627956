import { useState, useEffect } from "react";
import "./HowToUse.css";

// Material UI
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default function HowToUse() {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>How to use this course</h1>

      <Card sx={{ width: "100%", marginBottom: "12px" }}>
        <CardContent
          style={{
            paddingBottom: "0px",
          }}
        >
          {/* <h5 style={{ marginTop: 0 }}>Welcome!</h5> */}

          <p className="note unselectable">1. Select a topic from the left.</p>
          <p className="note unselectable">2. Type all of the course notes from memory in your own words.</p>
          <p className="note unselectable">3. Once you complete all notes for each section, you will earn a certificate for this course!</p>
        </CardContent>
        {/* <CardActions
          sx={{
            backgroundColor: "primary.light",
            // padding: "0px",
          }}
        >
          <Button
            size="small"
            style={{
              alignSelf: "center",
              width: "100%",
              // color: "white",
            }}
          >
            Type from Memory
          </Button>
        </CardActions> */}
      </Card>
    </div>
  );
}
