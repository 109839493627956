import { useState } from "react";
import "./ChatModal.css";

// Material UI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import CommentIcon from "@mui/icons-material/Comment";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const [userInput, setUserInput] = useState<string>("");

  interface Chat {
    user: string;
    message: string;
  }
  const [chat, setChat] = useState<Chat[]>([
    { user: "AI", message: "Hello! What can I answer for you?" },
  ]);

  function newMessage(message: string) {
      
    // zip chat up into a string like:
    // "{user}: {message}\n{user}: {message}\n{user}: {message}\n"
    const chatString = chat.map((c) => `${c.user}: ${c.message}`).join("\n");
    console.log(chatString);
    setChat((prev) => [...prev, { user: "You", message }]);

    fetch("https://groknotes-api-a14349186a2e.herokuapp.com/chat_json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ question: message, context: chatString }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setChat((prev) => [...prev, { user: "AI", message: data["AI"] }]);

        // wait 500ms for the chat to update, then scroll to bottom
        setTimeout(() => {
          // scroll #chatBox to the bottom
          const chatBox = document.getElementById("chatBox");
          chatBox?.scrollTo(0, chatBox.scrollHeight);
        }, 500);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div>
      <Fab
        onClick={handleOpen}
        variant="extended"
        color="primary"
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          zIndex: "999",
        }}
      >
        <CommentIcon sx={{ mr: 1 }} />
        Ask a question
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div 
            id="chatBox"
            style={{ 
                maxHeight: "50vh", 
                overflowY: "scroll", 
                width: "100%",
            }}
          >
            {chat.map((c, i) => (
                <div
                    style={{ display: "flex", justifyContent: c.user === "AI" ? "flex-start" : "flex-end" }}
                >
                    <p key={i} className={c.user === "AI" ? "aiMessageBubble" : "userMessageBubble"}>
                        {c.message}
                    </p>
                </div>
            ))}
          </div>
          <br />
          <div>
            <div style={{ display: "flex" }}>
              <TextField
                id="outlined-basic"
                label="Ask a question"
                variant="outlined"
                sx={{ marginRight: "12px", display: "flex", flexGrow: "1" }}
                autoComplete="off"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    newMessage(userInput);
                    setUserInput("");
                  }
                }}
              />

              <Button
                variant="contained"
                size="large"
                sx={{ marginRight: "12px" }}
                disabled={!userInput.trim()}
                onClick={() => {
                  newMessage(userInput);
                  setUserInput("");
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
