import "./CourseCard.css";

// Material UI
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// Routing
import { Link } from "react-router-dom";

// Types
interface CourseCardProps {
  card_header: string;
  course_name: string;
  description: string | null;
  card_image?: string | null;
}

export default function CourseCard({ card_header, course_name, description, card_image=null }: CourseCardProps) {
  // props

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ minWidth: 275 }}>
        {card_image && <CardMedia
          sx={{ height: 140 }}
          image={card_image}
        />}
        <CardContent>
          <p color="text.secondary">{card_header}</p>
          <b className="truncate-1-line">{course_name}</b>
          {description && <p className="truncate-2-lines">{description}</p>}
        </CardContent>
        <CardActions>
          <Link to={`/course/${course_name}`}>
            <Button size="small">View Course</Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
}
