import { useState, useEffect } from "react";
import "./Explore.css";
import { toTitleCase } from "../../utils/strings";

import Appbar from "../../components/Appbar/Appbar";
import CourseCard from "../../components/CourseCard/CourseCard";

// Routing
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Clerk
import { SignedOut, SignedIn } from "@clerk/clerk-react";

// UI Components
import { Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

export default function Explore() {
	// props
	const { subject } = useParams();

	// state
	const [suggestedCourses, setSuggestedCourses] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);

	// fetch data
	function fetchSuggestedCourses() {
		setLoading(true);
		fetch("https://groknotes-api-a14349186a2e.herokuapp.com/get_suggestions/" + subject, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				// set suggested courses as everything in suggested courses + data
				setSuggestedCourses([...data]);
				setLoading(false);
			});
	}

	function fetchNewSuggestedCourses() {
		setLoading(true);
		fetch("https://groknotes-api-a14349186a2e.herokuapp.com/get_suggestions/" + subject, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				// set suggested courses as everything in suggested courses + data
				setSuggestedCourses([...suggestedCourses, ...data]);
				setLoading(false);
			});
	}

	// effects
	useEffect(() => {
		setSuggestedCourses([]);
		fetchSuggestedCourses();
	}, [subject]);

	return (
		<>
			<Appbar />
				<div className="dashboard" style={{backgroundColor: "#f5f5f5", minHeight: "100vh"}}>
					<h1>Explore {toTitleCase(subject? subject : "")} Courses</h1>

					{/* map over Suggested courses */}
					<Grid container spacing={2}>
						{suggestedCourses?.map((course: any) => {
							return (
								<CourseCard course_name={course.course_name} card_header="Popular" description={course.description} />
							);
						})}
					</Grid>
					{ loading && 
						<>
							<br />
							<LinearProgress />
						</>
					}
					{ !loading && 
						<>
							<br /><br />
							<Button 
								variant="contained" 
								size="large" 
								sx={{
									width: "100%",
								}}
								onClick={() => fetchNewSuggestedCourses()}
							>
								Load More
							</Button>
						</>
					}
				</div>
		</>
	);
}