import { useState, useEffect } from "react";
import "./Appbar.css";
import { toTitleCase } from "../../utils/strings";

// Material UI
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, alpha } from '@mui/material/styles';

// Material Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";

// Clerk Imports
import { SignedOut, SignedIn } from "@clerk/clerk-react";
import { SignOutButton, SignInButton } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";

// Router Imports
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1, 1),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    fontSize: "16px",
    fontWeight: "lighter",
  },
}));

export default function Appbar() {
  // state
  const navigate = useNavigate();
  const { isSignedIn, user, isLoaded } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);

  const subjects = [
    "Math", 
    "Science", 
    "Literature", 
    "History", 
    "Computer Science",
    "Business",
    "Finance",
    "Personal Development",
    "Design",
    "Marketing",
    "Lifestyle",
    "Health and Fitness",
    "Music",
  ];

  // functions
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  function customTopic() {
    var topic = prompt("Enter a topic you would like to learn about", "");
    if (topic != null && topic != "") {
      topic = toTitleCase(topic);
      navigate("/explore/" + topic);
    }
  }

  // effects

  return (
    <AppBar position="static" style={{ boxShadow: "none" }}>
      <Toolbar>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <Link to="/">
            <Button className="logo-button">GrokNotes</Button>
          </Link>

          <Link to="/" className="link">
            <Button style={{ marginRight: "16px", marginTop: "5.5px" }} color="inherit">Home</Button>
          </Link>

          <Button
            color="inherit"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            endIcon={<ArrowDropDownIcon />}
            style={{ marginRight: "16px" }}
          >
            Explore
          </Button>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              marginLeft: "-110px",
            }}
          >
            {subjects.map((subject) => (
              <Link to={"/explore/" + subject} className="menu-link">
                <MenuItem onClick={handleClose}>
                  {subject}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </MenuItem>
              </Link>
            ))}
          </Menu>

          {/* <Link to="/explore" className="link">
            <Button style={{ marginRight: "16px", marginTop: "5.5px" }} color="inherit">Explore the catalogue</Button>
          </Link> */}

          <Search
            sx={{ 
              flexGrow: 1,
              marginRight: "24px"
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="What would you like to learn about?"
              inputProps={{ 'aria-label': 'search' }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  var topic = e.currentTarget.value;
                  if (topic != null && topic.trim() != "") {
                    topic = toTitleCase(topic);
                    navigate("/explore/" + topic);
                    // clear the search bar
                    // e.currentTarget.value = "";
                  }
                }
              }}
            />
          </Search>
        </Box>

        <SignedIn>
          <Link to="/user_dashboard" className="link hide-on-mobile">
            <Button style={{ marginRight: "16px" }} color="inherit">
              My Dashboard
            </Button>
          </Link>
        </SignedIn>

        {/* <IconButton style={{ marginRight: "16px" }} onClick={customTopic}>
          <SearchIcon style={{ color: "white" }} />
        </IconButton> */}

        <SignedOut>
          <SignInButton>
            <Button style={{ marginRight: "16px" }} color="inherit">Login</Button>
          </SignInButton>

          <SignInButton>
            <Button variant="contained" color="secondary">Sign Up</Button>
          </SignInButton>
        </SignedOut>

        <SignedIn>
          <IconButton
            aria-controls="profile-menu-appbar"
            aria-haspopup="true"
            onClick={handleProfileMenu}
          >
            <Avatar
              alt="User Image"
              src={
                user?.imageUrl === ""
                  ? "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                  : user?.imageUrl
              }
            />
          </IconButton>

          <Menu
            id="profile-menu-appbar"
            anchorEl={profileAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(profileAnchorEl)}
            onClose={handleProfileClose}
          >

            <Link to="/user_profile" className="menu-link">
              <MenuItem onClick={handleProfileClose}>
                Profile
              </MenuItem>
            </Link>

            <Link to="/" className="menu-link">
              <SignOutButton>
                <MenuItem onClick={handleProfileClose}>
                  Log Out
                </MenuItem>
              </SignOutButton>
            </Link>
          </Menu>
        </SignedIn>
      </Toolbar>
    </AppBar>
  );
}
