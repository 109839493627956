import { Fragment } from "react";
import Appbar from "../../components/Appbar/Appbar";

import { UserProfile } from "@clerk/clerk-react";
 
export default function UserProfilePage() {
  return (
    <>
      <Appbar />
      <div
        style={{
          marginLeft: "-30px",
        }}
      >
        <UserProfile path="/user_profile" routing="hash" />
      </div>
    </>
  );
}