import "./Home.css";
import { useEffect } from "react";

import { useTheme } from "@mui/material/styles";

import Appbar from "../../components/Appbar/Appbar";
import CourseCard from "../../components/CourseCard/CourseCard";

// Material UI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// Router Stuff
import { Link } from "react-router-dom";

// Clerk Imports
import { SignedOut, SignedIn } from "@clerk/clerk-react";
import { SignOutButton, SignInButton } from "@clerk/clerk-react";

export default function Home() {
	const theme = useTheme();

	function wakeUpServer() {
		fetch("https://groknotes-api-a14349186a2e.herokuapp.com/", {
			method: "GET",
		}).then((response) => {
			console.log("gm");
		});
	}

	useEffect(() => {
		wakeUpServer();
	}, []);

	return (
		<>
			<Appbar />
			<div id="header">
				<Grid container>
					<Grid item xs={12} md={6}>
						<h1 className="header-title">Learn anything.</h1>
						<p className="header-text">
							GrokNotes is more than AI-generated flashcards: it's the #1 most
							comprehensive learning platform. Join our community of lifelong
							learners using GrokNotes's infinite course catalog, active retention strategies, and
							AI-powered tools to deepen their knowledge.
						</p>

						<br />

						<SignedOut>
							<SignInButton>
								<Button variant="contained" size="large">Sign up now</Button>
							</SignInButton>
						</SignedOut>

						<SignedIn>
							<Link to="/user_dashboard">
								<Button variant="contained" size="large">My Dashboard</Button>
							</Link>
						</SignedIn>
					</Grid>
				</Grid>
			</div>

			<div id="suggestions">
				<Grid container>
					<Grid item xs={12}>
						<h1 className="suggestions-title">Every subject in one place</h1>
						<p className="suggestions-text">
							From artificial intelligence to global history, our catalog is truly endless.
						</p>

						<br />
						<Grid container spacing={2}>
						<CourseCard 
							card_header="Unlock the Future" 
							course_name="Artificial Intelligence in Modern Society" 
							description="Explore the transformative power of AI and machine learning technologies. Understand their applications, ethical implications, and potential to reshape industries, from healthcare to entertainment." 
							card_image="https://images.unsplash.com/photo-1589254065878-42c9da997008?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
						/>
						<CourseCard 
							card_header="Change the World" 
							course_name="Environmental Sustainability" 
							description="Dive into the principles of environmental science, sustainability practices, and green technologies. Learn about efforts to combat climate change, conserve biodiversity, and promote a sustainable lifestyle." 
							card_image="https://images.unsplash.com/photo-1466611653911-95081537e5b7?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
						/>
						<CourseCard 
							card_header="Explore Within" 
							course_name="Psychology: Understanding the Mind" 
							description="Unravel the complexities of the human mind. This course offers insights into mental health, behavioral psychology, and cognitive processes, providing tools for self-improvement and understanding human behavior." 
							card_image="https://images.unsplash.com/photo-1509909756405-be0199881695?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
						/>
						<CourseCard 
							card_header="Launch Your Dream" 
							course_name="Introduction to Entrepreneurship" 
							description="Aimed at aspiring entrepreneurs and innovators, this course covers the essentials of starting a business, from ideation to execution. Learn about market analysis, funding strategies, and the entrepreneurial mindset." 
							card_image="https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
						/>
						<CourseCard 
							card_header="Beyond the Stars" 
							course_name="Astronomy: Secrets of the Universe" 
							description="Journey through the cosmos and uncover the mysteries of the universe. From the basics of astronomy to the latest in space exploration, this course is designed for anyone fascinated by the stars and beyond." 
							card_image="https://images.unsplash.com/photo-1502134249126-9f3755a50d78?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
						/>
						<CourseCard 
							card_header="Journey Through Time" 
							course_name="Global History: Cultures and Civilizations" 
							description="Embark on a historical journey across cultures and epochs. Discover the events, ideas, and movements that have shaped human civilization, fostering a deeper understanding of the world's diverse societies." 
							card_image="https://images.unsplash.com/photo-1580136579312-94651dfd596d?q=80&w=3003&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
						/>
						</Grid>
					</Grid>
				</Grid>
			</div>

			<div id="memory">
				<Grid container>
					<Grid item xs={12}>
						<h1 className="memory-title">Unlock the power of active learning</h1>
						<p className="memory-text">
						By engaging with the content on GrokNotes, you're not just passively consuming information; 
						you're ensuring that what you learn stays with you longer. 
						Our hands-on learning method is scientifically proven to enhance recall and comprehension, 
						making your learning experience more effective.
						</p>
						<img src="smth.png" className="memory-image" />

						<br />
					</Grid>
				</Grid>
			</div>

			<div id="pricing">
				<Grid container>
					<Grid item xs={12}>
						<h1 className="memory-title">#FreeEducation</h1>
						<p className="memory-text">
							Good education is a basic human right. All courses on GrokNotes are available for no cost!
						</p>

						<iframe width="100%" height="615" src="https://www.youtube.com/embed/hJP5GqnTrNo?si=MUmSRwyWfDtfdyIB" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
						
					</Grid>
				</Grid>
			</div>

			<div id="call-to-action">
				<Grid container>
					<Grid item xs={12}>
						<h1 className="memory-title">So, what are you waiting for?</h1>
						<br />

						<SignedOut>
							<SignInButton>
								<Button variant="contained" size="large">Sign up now!</Button>
							</SignInButton>
						</SignedOut>

						<SignedIn>
							<Link to="/user_dashboard">
								<Button variant="contained" size="large">Go to My Dashboard</Button>
							</Link>
						</SignedIn>
					</Grid>
				</Grid>
			</div>

			<div 
				id="footer" 
				style={{
					backgroundColor: theme.palette.primary.main,
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<p className="footer-text">
							© 2024 GrokNotes Inc. All rights reserved.
						</p>
					</Grid>
				</Grid>
			</div>

			<div id="menu">
				<Grid container></Grid>
			</div>
		</>
	);
}
