import { useState, useEffect } from "react";
import "./UserDashboard.css";

import Appbar from "../../components/Appbar/Appbar";
import CourseCard from "../../components/CourseCard/CourseCard";

// Clerk
import LoginRequired from "../LogInRequired/LoginRequired";
import { SignedOut, SignedIn } from "@clerk/clerk-react";

// UI Components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// Routing
import { Link } from "react-router-dom";

// Clerk Stuff
import { useUser } from "@clerk/clerk-react";

export default function UserDashboard() {
  // state
  const { isSignedIn, user, isLoaded } = useUser();
  const [yourCourses, setYourCourses] = useState<any>([]);
  const [recommendedCourses, setRecommendedCourses] = useState<any>([]);

  // fetch data
  function fetchYourCourses() {
    fetch("https://groknotes-api-a14349186a2e.herokuapp.com/get_user_courses/" + user?.emailAddresses[0].emailAddress, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setYourCourses(data["userCourses"]);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  function fetchRecommendedCourses() {
    fetch("https://groknotes-api-a14349186a2e.herokuapp.com/get_user_recommendations/" + user?.emailAddresses[0].emailAddress, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // log the type of data
        console.log(typeof data);
        setRecommendedCourses(data);
      });
  }

  // effects
  useEffect(() => {
    if (user) {
      fetchYourCourses();
    }
  }, [user]);

  useEffect(() => {
    fetchRecommendedCourses();
  }, []);

  return (
    <>
      <Appbar />

      <SignedIn>
        <div className="dashboard" style={{backgroundColor: "#f5f5f5", minHeight: "100vh"}}>
        { yourCourses?.length > 0 &&
          <>
            <h1>Your Courses</h1>

            <Grid container spacing={2}>
              {yourCourses?.map((course: any) => {
                return (
                  <CourseCard course_name={course} card_header="Your Course" description={null} />
                );
              })}
            </Grid>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </>
        }

          <h1>Recommended Courses</h1>

          { recommendedCourses.length === 0 && <LinearProgress /> }

          {/* map over recommended courses */}
          <Grid container spacing={2}>
            {recommendedCourses?.map((course: any) => {
              return (
                <CourseCard course_name={course.course_name} card_header="Recommended For You" description={course.description} />
              );
            })}
          </Grid>
        </div>
      </SignedIn>

      <SignedOut>
        <LoginRequired />
      </SignedOut>
    </>
  );
}
