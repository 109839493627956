import React from 'react';
import './App.css';

import Home from './pages/Home/Home';
import Explore from './pages/Explore/Explore';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import SearchResults from './pages/SearchResults/SearchResults';
import Course from './pages/Lesson/Lesson';
import UserProfile from './pages/UserProfile/UserProfile';


import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/explore/:subject' element={<Explore />} />
          <Route path='/user_dashboard' element={<UserDashboard />} />
          <Route path='/search_results' element={<SearchResults />} />
          <Route path='/course/:subject' element={<Course />} />
          <Route path='/user_profile' element={<UserProfile />} />
          <Route path='/user_profile/:' element={<UserProfile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
