export function removeBullet(text: string) {
    return text.slice(2);
}

export function addPeriod(text: string) {
    const last_char = text.slice(text.length - 1);
    if ( ![".", "?", "!"].includes(last_char) ) {
        return text + "."
    }
    return text
}

// function to convert text to titlecase, but if a word is all caps, it will stay all caps
export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function(txt) {
        if (txt === txt.toUpperCase()) {
            return txt;
        } else {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    });
}