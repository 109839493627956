import Appbar from "../../components/Appbar/Appbar";
import LessonCard from "../../components/LessonCard/LessonCard";
import HowToUse from "../../components/HowToUse/HowToUse";
import LoginRequired from "../LogInRequired/LoginRequired";
import ChatModal from "../../components/ChatModal/ChatModal";

import "./Lesson.css";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Clerk Stuff
import { useUser } from "@clerk/clerk-react";
import { SignedOut, SignedIn } from "@clerk/clerk-react";
import { SignInButton } from "@clerk/clerk-react";

// Material UI
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from '@mui/material/CircularProgress';

// Material Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardIcon from "@mui/icons-material/Keyboard";

// Other Stuff
import ProgressBar from "@ramonak/react-progress-bar";

export default function Course() {
  // props
  const { subject } = useParams();

  // state
  const { isSignedIn, user, isLoaded } = useUser();
  const [gettingSyllabus, setGettingSyllabus] = useState<boolean>(false);
  const [syllabus, setSyllabus] = useState<any>(null);
  const [currentSubtopic, setCurrentSubtopic] = useState<any>(null);
  const [gettingNotes, setGettingNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<any>(null);
  const [userCompletedSubtopics, setUserCompletedSubtopics] = useState<string[]>([""]);
  const [userCompletedNotes, setUserCompletedNotes] = useState<string[]>([""]);
  const [percentComplete, setPercentComplete] = useState<number>(0);

  // functions
  function getSyllabus(_subject: string | undefined) {
    _subject && fetch(
      `https://groknotes-api-a14349186a2e.herokuapp.com/get_syllabus/${encodeURIComponent(_subject)}/PHD`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error getting syllabus: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setSyllabus(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getNotes( _subtopic: string, _topic: string, _subject: string | undefined) {
    setCurrentSubtopic(_subtopic);
    setNotes(null);
    setGettingNotes(true);
    

    _subject && fetch(
      `https://groknotes-api-a14349186a2e.herokuapp.com/get_notes_json/${encodeURIComponent(_subject)}/${encodeURIComponent(_topic)}/${encodeURIComponent(_subtopic)}/PHD`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error getting notes: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setNotes(data);
        setGettingNotes(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getUserCompletedSubtopics() {
    fetch(
      `https://groknotes-api-a14349186a2e.herokuapp.com/get_user_completed_subtopics`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ course: subject, user_email: user?.emailAddresses[0].emailAddress }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error getting user completed subtopics: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUserCompletedSubtopics(data["completed_subtopics"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getUserCompletedNotes() {
    fetch(
      `https://groknotes-api-a14349186a2e.herokuapp.com/get_user_completed_notes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subtopic: currentSubtopic, user_email: user?.emailAddresses[0].emailAddress }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error getting user completed notes: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUserCompletedNotes(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // effects
  useEffect(() => {
    if (subject) {
      setCurrentSubtopic(null);
      setNotes(null);
      setSyllabus(null);
      setGettingSyllabus(true);
      getSyllabus(subject);
    }
  }, [subject]);

  useEffect(() => {
    if (currentSubtopic) {
      console.log("Getting user completed notes");
      getUserCompletedNotes();
    }
  }, [currentSubtopic]);

  useEffect(() => {
    if (user) {
      getUserCompletedSubtopics();
    }
  }, [user, syllabus]);

  useEffect(() => {
    if (syllabus && userCompletedSubtopics) {
      setPercentComplete(100 * userCompletedSubtopics.length / (syllabus.syllabus.length * syllabus.syllabus[0].subtopics.length));
    }
  }, [user, syllabus, userCompletedSubtopics]);

  return (
    <div className="main">
      <Appbar />
        <Grid container>
          <Grid
            item
            xs={3}
            style={{ height: "calc(100vh - 64px)", overflowY: "scroll" }}
          >
            <h1 style={{ textAlign: "center", paddingLeft: "10px", paddingRight: "10px" }}>{subject}</h1>
            <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingBottom: "36px" }}>
              <SignedIn>
                <p style={{margin: 0, padding: 0, marginBottom: "5px"}}>Progress:</p>
                {/* <progress value={percentComplete} style={{ width: "100%" }} /> */}
                <ProgressBar completed={percentComplete} bgColor="#388e3c" baseBgColor="#e5eaef" />
              </SignedIn>
              <SignedOut>
                <SignInButton>
                  <p style={{margin: 0, padding: 0, textAlign: "center", color: "#2196f3", textDecoration: "underline", cursor: "pointer"}}>
                      Log in to save your progress
                  </p>
                </SignInButton>
              </SignedOut>
            </div>


            {syllabus ? (
              syllabus.syllabus.map((topic: any, index: number) => {
                return (
                  <Accordion
                    key={topic.topic_name}
                    defaultExpanded={index === 0 ? true : false}
                    sx={{ boxShadow: 0 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {topic.topic_name}
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {topic.subtopics.map((subtopic: string) => {
                          return (
                            <ListItem disablePadding key={subtopic}>
                              <ListItemButton
                                onClick={() => {
                                  getNotes(subtopic, topic.topic_name, subject);
                                }}
                                // Use the sx prop for conditional styling based on the currentSubtopic
                                sx={{
                                  bgcolor: currentSubtopic === subtopic ? 'background.default' : 'inherit', // Change 'primary.main' to your desired color
                                  '&:hover': {
                                    bgcolor: 'background.default', // Optional: change color on hover
                                  },
                                }}
                              >
                                <ListItemIcon>
                                  <CheckCircleOutlineIcon className={ userCompletedSubtopics.includes(subtopic) ? "green" : "lightgrey" } />
                                </ListItemIcon>
                                <ListItemText primary={subtopic} className={ userCompletedSubtopics.includes(subtopic) ? "green" : "" } />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>
                <CircularProgress />
              </p>
            )}
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              height: "calc(100vh - 64px)",
              backgroundColor: "#f5f5f5",
              overflowY: "scroll",
            }}
          >
            <div id="notes-container">
              <br />
              <h1 style={{ textAlign: "center" }}>{currentSubtopic}</h1>

              {notes ? (
                notes.map((note: any, index: number) => {
                  if (note.includes("-")) {
                    return <LessonCard key={index} index={index} note={note} course={subject} subtopic={currentSubtopic} completed={userCompletedNotes.includes(note)} getUserCompletedSubtopics={getUserCompletedSubtopics} />;
                  }
                })
              ) : gettingNotes ? (
                <p id="notes">
                  <LinearProgress />
                </p>
              ) : (
                <HowToUse />
              )}
            </div>
            
          </Grid>
        </Grid>
      <ChatModal />
    </div>
  );
}
