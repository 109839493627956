import { useState, useEffect } from "react";
import "./LessonCard.css"

// Utils
import { removeBullet, addPeriod } from "../../utils/strings";

// Material UI
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

// Material Icons
import KeyboardIcon from "@mui/icons-material/Keyboard";

// Clerk Stuff
import { useUser } from "@clerk/clerk-react";

// Types
interface LessonCardProps {
  index: number;
  note: string;
  course: string | undefined;
  subtopic: string;
  completed: boolean;
  getUserCompletedSubtopics?: Function;
}

export default function LessonCard({ index, note, course, subtopic, completed, getUserCompletedSubtopics }: LessonCardProps) {
  // state
  const { isSignedIn, user, isLoaded } = useUser();
  const [typing, setTyping] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>("");
  const [checkingUserInput, setCheckingUserInput] = useState<boolean>(false);
  const [userInputRight, setUserInputRight] = useState<boolean>(completed);
  const [userInputWrong, setUserInputWrong] = useState<boolean>(false);
  const [userInputWrongReason, setUserInputWrongReason] = useState<string>("");

  // functions
  function checkUserInput() {
      if (userInput.trim()) {
        console.log(note);
        console.log(userInput);

        setCheckingUserInput(true);
        setUserInputWrongReason("");
        
        fetch("https://groknotes-api-a14349186a2e.herokuapp.com/testy", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            "body": JSON.stringify({"original": note, "input": userInput}),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}`)
            }
            return response.json()
        })
        .then((data) => {
            console.log(data);
            setCheckingUserInput(false);

            if (data.answer === "False") {
                setUserInputRight(false);
                setUserInputWrong(true);
                setUserInputWrongReason(data.reason);
            } else {
                setTyping(false);
                setUserInputRight(true);
                setUserInputWrong(false);

                // Post the completed note
                if (user) {
                  postCompletedNote(course?course:"", subtopic, note, userInput);
                  if (getUserCompletedSubtopics) {
                    getUserCompletedSubtopics();
                  }
                }
            }

        })
        .catch((error) => {
            console.log(`Error checking user input: ${error}`)
        })
    }
  }

  function postCompletedNote(course: string, subtopic: string, note: string, user_input: string) {
    fetch("https://groknotes-api-a14349186a2e.herokuapp.com/post_completed_note", {
      "method": "POST",
      headers: {
        'Content-Type': 'application/json', // Specify the content type as JSON
      },
      "body": JSON.stringify({"course": course, "subtopic": subtopic, "note": note, "user_email": user?.emailAddresses[0].emailAddress, "user_input": user_input})
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`${response.status}`)
      }
      return response.json()
    }).then((data) => {
      console.log(data)
    }).catch((error) => {
      console.log(`Error posting completed note: ${error}`)
    })
  }

  // effects

  return (
    <Card sx={{ width: "100%", marginBottom: "12px" }}>
      <CardContent
        style={{
          paddingBottom: "0px",
        }}
      >
        <h5 style={{marginTop: 0}}>Note {index + 1}:</h5>


        { typing ? 
            <>
                <div style={{display: "flex", margin: "40px"}}>
                    <TextField 
                        id="outlined-basic" 
                        label="Type the note in your own words from memory"
                        focused={typing}
                        variant="outlined" 
                        sx={{ marginRight: "12px", display: "flex", flexGrow: "1" }} 
                        onChange={(e) => setUserInput(e.target.value)}
                        // handle enter key
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                checkUserInput();
                            }
                        }}
                        value={userInput}
                        autoComplete="off"
                        onPaste={(e: any) => {
                          e.preventDefault();
                          return false;
                        }}
                    />

                    <Button 
                        variant="contained" 
                        size="large" 
                        sx={{marginRight: "12px"}}
                        disabled={checkingUserInput || !userInput.trim()}
                        onClick={() => checkUserInput()}
                    >
                        Submit
                    </Button>
                    <Button onClick={() => { setTyping(false) }} variant="outlined" size="large" sx={{marginRight: "12px"}}>Cancel</Button>
                </div>
                { userInputWrong && <p style={{margin: "40px", marginTop: "0px"}} className="red italics unselectable">{userInputWrongReason}</p> }
            </>
            : 
              completed || userInputRight ?
                    <p className="note unselectable green">{addPeriod(removeBullet(note))}</p> 
                : userInputWrong ?
                    <p className="note unselectable red">{addPeriod(removeBullet(note))}</p> 
                :
                    <p className="note unselectable">{addPeriod(removeBullet(note))}</p> 
        }


      </CardContent>
      <CardActions
        sx={{
          backgroundColor: "background.default",
          // padding: "0px",
        }}
      >
        <Button
          size="small"
          startIcon={<KeyboardIcon />}
          onClick={() => {
            setTyping(true)
          }}
          style={{ 
              alignSelf: "center",
              width: "100%",
              // color: "white",
          }}
        >
          Type from Memory
        </Button>
      </CardActions>
    </Card>
  );
}
